<script setup lang="ts">
import BasicButton from '../commons/BasicButton.vue'
import ContentSection from '../base/ContentSection.vue'
import { FormKit } from '@formkit/vue'
import { getNode, FormKitNode } from '@formkit/core'
import { ref, reactive, computed, ComputedRef, nextTick, watch, toRaw } from 'vue'
import { formatCurrencyWithDollar, getMonthName } from '../../_helpers/common.helpers'
import { useI18NStore } from '../../stores/i18n'
import { useSukuBudgetStore, OperationalMonthlyReportDataContext, OperationalMonthlyReportCommulativeContext, OperationalMonthlReportBudget, OperationalMonthlyReportBalance, InfrastructureReportData } from '../../stores/sukubudget'
import { components } from '../../_api-services/openapi'
import { SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE, SUKU_BUDGET_MONTHLY_REPORT_CREATE, SUKU_BUDGET_MONTHLY_REPORT_STATUS, SUKU_BUDGET_TYPE, SUKU_BUDGET_STATUS, SUKU_BUDGET_TYPES } from '../../_constants/sukumanager.constant'
import { useOptionsStore } from '../../stores/options'
import { format } from 'date-fns'
import { useModal } from '../../stores/modal'
import { useSubmissionsStore } from '../../stores/submissions'
import { useAppStore } from '../../stores/app'
import { useAuthStore } from '../../stores/auth'
import { FORM_TYPES } from '../../_constants/common.constant'
import { router } from '../../router'
import MonthlyReportOperational from './MonthlyReportOperational.vue'
import MonthlyReportFilter from './MonthlyReportFilter.vue'
import MonthlyReportInfrastructure from './MonthlyReportInfrastructure.vue'
import MonthlyReportOperationalModal from './MonthlyReportOperationalModal.vue'
import MonthlyReportInfrastructureModal from './MonthlyReportInfrastructureModal.vue'
import { MonthlyReportTotalDataSchema, MonthlyReportFormKitDataSchema } from '../../_types/sukubudget'

const i18nstore = useI18NStore()
const sukuBudgetStore = useSukuBudgetStore()
const showMonthlyReport = ref(false)
const optionsStore = useOptionsStore()
const modal = useModal()
const submissionStore = useSubmissionsStore()
const appStore = useAppStore()
const authStore = useAuthStore()

const monthlyReportModal = ref()

await optionsStore.initialize()
type FF12RepeaterInfrastructureFundContext = components['schemas']['Cfm_12_ff_12RepeaterinfrastructurefundSchema']

interface FF12RepeaterInfrastructureFundContextExtends extends FF12RepeaterInfrastructureFundContext {
  [key: string]: string | number | undefined
}

const monthlyReportFilterValues = ref<{year: number | undefined, month: number | undefined, actionType: string}>({
  year: undefined,
  month: undefined,
  actionType: SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE
})

const activeBudget = computed(() => sukuBudgetStore.$state.activeBudget)
const reportDate = format(new Date(), 'dd/MM/yy')
const selectedMonthlyReport = computed(() => sukuBudgetStore.$state.selectedFF12Submission)

const reportingPeriod = ref('')

const monthlyReportData: ComputedRef<OperationalMonthlyReportDataContext> = computed(() => sukuBudgetStore.getOperationalMonthlyReportsData)
const monthlyReportCommulative: ComputedRef<OperationalMonthlyReportCommulativeContext> = computed(() => sukuBudgetStore.getOperationalMonthlyReportCommulative)
const monthlyReportBudget: ComputedRef<OperationalMonthlReportBudget> = computed(() => sukuBudgetStore.getOperationalMonthlyReportBudget)
const monthlyReportBalance: ComputedRef<OperationalMonthlyReportBalance> = computed(() => sukuBudgetStore.getOperationalMonthlyReportBalance)
const monthlyReportInfrastructureExpenditures: ComputedRef<InfrastructureReportData> = computed(() => sukuBudgetStore.getInfrastructureReportData)
const infrastructureMontlyReportBalance = computed(() => sukuBudgetStore.getInfrastructureMonthlyReportBalance)

const monthlyReportValues = ref<Record<string, any>>({})

const isFiscalYearClosed = computed(() => {
  const preparationData = sukuBudgetStore.getPom1SubmissionByYear(Number(monthlyReportFilterValues.value.year))
  return activeBudget.value === SUKU_BUDGET_TYPE.operational ? preparationData?.pom1_operational_status === SUKU_BUDGET_STATUS.closed : preparationData?.pom1_infrastructure_status === SUKU_BUDGET_STATUS.closed
})

const isFF12Verified = ref(false)

const monthlyReportProcess = async (values: { year: number | undefined, month: number | undefined, actionType: string }) => {
  if (typeof values.year === 'undefined' || typeof values.month === 'undefined') return
  reportingPeriod.value = `${getMonthName(Number(monthlyReportFilterValues.value.month ?? 1))} ${monthlyReportFilterValues.value.year}`

  sukuBudgetStore.$state.monthlyReportsActiveParams = values
  await sukuBudgetStore.fetchFF12Data(values)
  monthlyReportValues.value = {
    interest_1002: '0',
    submission_id: monthlyReportData.value.data?.submission_id,
    other_1003: '0',
    operational_subsidy_1000: '0',
    last_year_balance_1001: '0',
    ...monthlyReportData.value.data
  }

  if (monthlyReportFilterValues.value.actionType === SUKU_BUDGET_MONTHLY_REPORT_VISUALIZE) {
    if (typeof monthlyReportData.value.data !== 'undefined') {
      monthlyReportModal?.value.toggleModal(true)
    }
  } else if (monthlyReportFilterValues.value.actionType === SUKU_BUDGET_MONTHLY_REPORT_CREATE) {
    showMonthlyReport.value = true
    // force update formkit field
    await monthlyReportData.value?.data?.repeaterInfrastructureFund?.forEach((fund, index) => {
      getNode(`materials_3000_index_${index}_${fund.project}`)?.input(fund?.materials_3000)
      getNode(`labour_incentive_3001_index_${index}_${fund.project}`)?.input(fund?.labour_incentive_3001)
    })
    await nextTick()
    calculateTotalData()

    isFF12Verified.value = monthlyReportData.value?.data?.ff12_status === SUKU_BUDGET_MONTHLY_REPORT_STATUS.verified
  }
}

const totalData = reactive<MonthlyReportTotalDataSchema>({
  totalOperationalReceiptsCummulative: 0,
  totalOperationalExpendituresCummulative: 0,
  totalOperationalFundBalanceThisYear: 0,
  totalDifferenceBalance: 0,
  totalOperationalExpendituresBalance: 0,
  totalInfrastructureCummulative: 0,
  repeaterInfrastructureExpenditures: [],
  totalInfrastructureExpendituresCummulative: 0,
  totalInfrastructureExpendituresThisMonth: 0,
  totalInfrastructureExpendituresBalance: 0,
  totalInfrastructureFundBalanceThisYear: 0,
  infrastructureBalanceDifference: 0
})

const formkitSchemaData: ComputedRef<MonthlyReportFormKitDataSchema> = computed(() => ({
  gettext: i18nstore.gettext,
  parseToFloat: (value: number): number => {
    if (!value) {
      return 0
    }
    return parseFloat(parseFloat(value.toString().replace(/[^0-9.]/g, '')).toFixed(2))
  },
  parseToCurrency: (value: number): string => {
    return formatCurrencyWithDollar(value, 'decimal')
  },
  monthlyReportData: monthlyReportData.value,
  monthlyReportCommulative: monthlyReportCommulative.value,
  monthlyReportBudget: monthlyReportBudget.value,
  monthlyReportInfrastructureExpendituresCummulative: monthlyReportInfrastructureExpenditures.value?.cummulative,
  totalData,
  getProjectName: (projectId: string): string => {
    return i18nstore.gettext(optionsStore.getOutputById(projectId)?.output ?? '')
  }
}))

const calculateTotalData = async () => {
  const totalOperationalReceiptsCummulative = monthlyReportCommulative.value.interest_1002 + formkitSchemaData.value.parseToFloat(getNode('interest_1002')?.value as number ?? 0) +
  monthlyReportCommulative.value.other_1003 + formkitSchemaData.value.parseToFloat(getNode('other_1003')?.value as number ?? 0) +
  monthlyReportCommulative.value.last_year_balance_1001 + formkitSchemaData.value.parseToFloat(getNode('last_year_balance_1001')?.value as number ?? 0) +
  monthlyReportCommulative.value.operational_subsidy_1000 + formkitSchemaData.value.parseToFloat(getNode('operational_subsidy_1000')?.value as number ?? 0)

  const totalOperationalExpendituresCummulative = monthlyReportCommulative.value.community_meetings_2000 + formkitSchemaData.value.parseToFloat(getNode('community_meetings_2000')?.value as number ?? 0) +
  monthlyReportCommulative.value.community_training_2001 + formkitSchemaData.value.parseToFloat(getNode('community_training_2001')?.value as number ?? 0) +
  monthlyReportCommulative.value.incentive_for_suku_2002 + formkitSchemaData.value.parseToFloat(getNode('incentive_for_suku_2002')?.value as number ?? 0) +
  monthlyReportCommulative.value.project_admin_cost_2003 + formkitSchemaData.value.parseToFloat(getNode('project_admin_cost_2003')?.value as number ?? 0)

  const totalOperationalExpendituresBalance = (monthlyReportBudget.value.community_meetings_2000 - (monthlyReportCommulative.value.community_meetings_2000 + formkitSchemaData.value.parseToFloat(getNode('community_meetings_2000')?.value as number ?? 0))) +
  (monthlyReportBudget.value.community_training_2001 - (monthlyReportCommulative.value.community_training_2001 + formkitSchemaData.value.parseToFloat(getNode('community_training_2001')?.value as number ?? 0))) +
  (monthlyReportBudget.value.incentive_for_suku_2002 - (monthlyReportCommulative.value.incentive_for_suku_2002 + formkitSchemaData.value.parseToFloat(getNode('incentive_for_suku_2002')?.value as number ?? 0))) +
  (monthlyReportBudget.value.project_admin_cost_2003 - (monthlyReportCommulative.value.project_admin_cost_2003 + formkitSchemaData.value.parseToFloat(getNode('project_admin_cost_2003')?.value as number ?? 0)))

  const totalInfrastructureReceiptsCummulative = monthlyReportCommulative.value.infrastructure_receipts_pnds_infrastructure_subsidy + formkitSchemaData.value.parseToFloat(getNode('infrastructure_receipts_pnds_infrastructure_subsidy')?.value as number ?? 0) +
  monthlyReportCommulative.value.infrastructure_receipts_last_year_balance + formkitSchemaData.value.parseToFloat(getNode('infrastructure_receipts_last_year_balance')?.value as number ?? 0) +
  monthlyReportCommulative.value.infrastructure_receipts_interest + formkitSchemaData.value.parseToFloat(getNode('infrastructure_receipts_interest')?.value as number ?? 0) +
  monthlyReportCommulative.value.infrastructure_receipts_others + formkitSchemaData.value.parseToFloat(getNode('infrastructure_receipts_others')?.value as number ?? 0)

  totalData.totalOperationalExpendituresCummulative = totalOperationalExpendituresCummulative
  totalData.totalOperationalReceiptsCummulative = totalOperationalReceiptsCummulative
  totalData.totalOperationalFundBalanceThisYear = totalOperationalReceiptsCummulative - totalOperationalExpendituresCummulative
  totalData.totalDifferenceBalance = (totalOperationalReceiptsCummulative - totalOperationalExpendituresCummulative) - monthlyReportBalance.value.total_operational_balance
  totalData.totalOperationalExpendituresBalance = totalOperationalExpendituresBalance
  await nextTick()
  // infrastucture calculation
  let totalInfrastructureExpendituresThisMonth = 0
  let totalInfrastructureExpendituresCummulative = 0
  let totalInfrastructureExpendituresBalance = 0

  const funds = monthlyReportData.value?.data?.repeaterInfrastructureFund ?? []
  const infrastructureFunds = funds.map((fund, index) => {
    const budgetMaterial3000 = monthlyReportInfrastructureExpenditures.value.budget[fund.project ?? '']?.materials_3000 ?? 0
    const budgetLabourIncentive3001 = monthlyReportInfrastructureExpenditures.value.budget[fund.project ?? '']?.labour_incentive_3001 ?? 0
    const materials3000 = formkitSchemaData.value.parseToFloat(getNode(`materials_3000_index_${index}_${fund.project}`)?.value as number ?? 0)
    const labourIncentive3001 = formkitSchemaData.value.parseToFloat(getNode(`labour_incentive_3001_index_${index}_${fund.project}`)?.value as number ?? 0)
    const materials3000cummulative = materials3000 + (monthlyReportInfrastructureExpenditures.value.cummulative[fund.project ?? '']?.materials_3000 ?? 0)
    const labourIncentive3001cummulative = labourIncentive3001 + (monthlyReportInfrastructureExpenditures.value.cummulative[fund.project ?? '']?.labour_incentive_3001 ?? 0)
    const balanceMaterials3000 = budgetMaterial3000 - materials3000cummulative
    const balanceLabourIncentive3001 = budgetLabourIncentive3001 - labourIncentive3001cummulative
    totalInfrastructureExpendituresThisMonth += materials3000 + labourIncentive3001
    totalInfrastructureExpendituresCummulative += materials3000cummulative + labourIncentive3001cummulative
    totalInfrastructureExpendituresBalance += balanceMaterials3000 + balanceLabourIncentive3001

    const spentMaterials3000 = formkitSchemaData.value.parseToFloat((materials3000cummulative / budgetMaterial3000) * 100)
    const spentLabourIncentive = formkitSchemaData.value.parseToFloat((labourIncentive3001cummulative / budgetLabourIncentive3001) * 100)

    return {
      ...fund,
      materials3000cummulative,
      labourIncentive3001cummulative,
      balanceMaterials3000,
      balanceLabourIncentive3001,
      budgetMaterial3000,
      budgetLabourIncentive3001,
      spentMaterials3000: isNaN(spentMaterials3000) ? 0 : spentMaterials3000,
      spentLabourIncentive: isNaN(spentLabourIncentive) ? 0 : spentLabourIncentive
    }
  })
  totalData.totalInfrastructureCummulative = totalInfrastructureReceiptsCummulative
  totalData.repeaterInfrastructureExpenditures = infrastructureFunds
  totalData.totalInfrastructureExpendituresThisMonth = totalInfrastructureExpendituresThisMonth
  totalData.totalInfrastructureExpendituresCummulative = totalInfrastructureExpendituresCummulative
  totalData.totalInfrastructureExpendituresBalance = totalInfrastructureExpendituresBalance

  // fund balance
  const totalInfrastructureFundBalanceThisYear = totalInfrastructureReceiptsCummulative - totalInfrastructureExpendituresCummulative
  totalData.totalInfrastructureFundBalanceThisYear = totalInfrastructureFundBalanceThisYear
  totalData.infrastructureBalanceDifference = totalInfrastructureFundBalanceThisYear - Number(infrastructureMontlyReportBalance.value.total_balance)
}

const save = async () => {
  if (isFF12Verified.value === true) return
  modal.open('info', {
    label: 'Save',
    modalTitle: 'Are you sure you’d like to update the FF12 submission?',
    modalIcon: 'la-stamp',
    modalCaption: 'By update this FF12 submission it will be stored in our history system.',
    callback: async () => {
      appStore.$state.loading = true
      if (typeof monthlyReportData.value?.data?.submission_id !== 'undefined') {
        const values = monthlyReportValues.value as unknown as Record<string, never>
        delete values.submission_id
        await submissionStore.updateSubmission(monthlyReportData.value.data?.submission_id, FORM_TYPES.CFM12FF12, values)
        appStore.$state.loading = false
      }
      modal.close()
    }
  })
}

const updateMonthlyReportStatus = async (status: string) => {
  modal.open('info', {
    label: 'Save',
    modalTitle: status === SUKU_BUDGET_MONTHLY_REPORT_STATUS.verified ? 'Are you sure you’d like to verify this report?' : 'Are you sure you’d like to unverify this report?',
    modalIcon: 'la-stamp',
    modalCaption: status === SUKU_BUDGET_MONTHLY_REPORT_STATUS.verified ? 'By verifying this report it will become verified in the system.' : 'By unverifying this report it will become unverified in the system.',
    callback: async () => {
      appStore.$state.loading = true
      if (typeof monthlyReportData.value?.data?.submission_id !== 'undefined') {
        const values = monthlyReportValues.value as unknown as Record<string, never>
        delete values.submission_id
        values.ff12_status = status as never
        await submissionStore.updateSubmission(monthlyReportData.value.data?.submission_id, FORM_TYPES.CFM12FF12, values)
        appStore.$state.loading = false
        isFF12Verified.value = status === SUKU_BUDGET_MONTHLY_REPORT_STATUS.verified
      }
      modal.close()
    }
  })
}

const onInputInfrastructureField = (val: string, node: FormKitNode) => {
  const activeProjectName = node.props?.attrs['data-project_name']
  const activeIndex = node.props?.attrs['data-index'] as number
  const activeField = node.props?.attrs['data-field'] as keyof FF12RepeaterInfrastructureFundContextExtends
  monthlyReportValues.value?.repeaterInfrastructureFund?.forEach((item: FF12RepeaterInfrastructureFundContextExtends, index: number) => {
    if (item.project_name === activeProjectName && index === activeIndex) {
      item[activeField] = val
    }
  })
}

// watch if the user move to another budget type, then recalculate all fields
watch(
  () => router.currentRoute.value,
  (newPath, oldPath) => {
    if (newPath.params.section !== oldPath.params.section) {
      showMonthlyReport.value = false
      monthlyReportProcess(monthlyReportFilterValues.value)
    }
  }
)

/**
 * submit report process
 * this will calculate the monthly report data
 * to force component to be rendered, we need to set `showMonthlyReport` to false. then in the `monthlyReportProcess` it will be set to `true`
 */
async function submitReport () {
  showMonthlyReport.value = false
  monthlyReportProcess(monthlyReportFilterValues.value)
}

const closeFiscalYear = () => {
  modal.open('confirm', {
    label: 'Close fiscal year',
    modalTitle: 'Are you sure?',
    modalCaption: 'Do you really want to close this fiscal year? This process cannot be undone',
    callback: async () => {
      // get selected pom1 by year
      const activePreparationData = sukuBudgetStore.getPom1SubmissionByYear(Number(monthlyReportFilterValues.value.year)) as unknown as Record<string, never>
      if (activePreparationData) {
        await sukuBudgetStore.updateBudgetStatus(FORM_TYPES.POM1, SUKU_BUDGET_STATUS.closed, activePreparationData)
      }
      modal.close()
    }
  })
}
</script>
<template>
  <!-- select period -->
  <MonthlyReportFilter
    v-model="monthlyReportFilterValues"
    :show-report="showMonthlyReport"
    :has-report="selectedMonthlyReport.length > 0"
    @submit="submitReport"
  />
  <!-- edit and create section -->
  <FormKit
    v-if="showMonthlyReport && selectedMonthlyReport.length > 0"
    v-model="monthlyReportValues"
    type="form"
    :actions="false"
    :disabled="isFF12Verified || !authStore.canAny(['ida_forms.add_pom_1', 'ida_forms.change_pom_1'])"
    @input="calculateTotalData"
  >
    <div class=" flex items-center">
      <span class="text-sm inline-block mr-6">
        <i class="lar la-calendar"></i> <span class="font-semibold">{{ gettext('Reporting period') }}</span> {{ reportingPeriod }}
      </span>
      <span class="text-sm inline-block">
        <span class="font-semibold">{{ gettext('Report date') }}</span> {{ reportDate }}
      </span>
    </div>
    <!-- SUKU OPERATIONAL BUDGET -->
    <!-- MonthlyReportOperational -->
    <MonthlyReportOperational
      v-if="activeBudget === SUKU_BUDGET_TYPE.operational"
      :formkit-schema-data="formkitSchemaData"
      :monthly-report-balance="monthlyReportBalance"
      :monthly-report-commulative="monthlyReportCommulative"
      :total-difference-balance="totalData.totalDifferenceBalance"
      :total-operational-fund-balance-this-year="totalData.totalOperationalFundBalanceThisYear"
    />
    <!-- SUKU INFRASTRUCTURE BUDGET -->
    <MonthlyReportInfrastructure
      v-if="activeBudget === SUKU_BUDGET_TYPE.infrastructure"
      :formkit-schema-data="formkitSchemaData"
      :infrastructure-montly-report-balance="infrastructureMontlyReportBalance"
      :infrastructure-funds-data="totalData.repeaterInfrastructureExpenditures"
      :total-balance-this-year="totalData.totalInfrastructureFundBalanceThisYear"
      :total-difference-balance="totalData.infrastructureBalanceDifference"
      :total-infrastructure-expenditures-this-month="totalData.totalInfrastructureExpendituresThisMonth"
      :total-infrastructure-expenditures-cummulative="totalData.totalInfrastructureExpendituresCummulative"
      :total-budget="monthlyReportInfrastructureExpenditures?.total_budget ?? 0"
      :total-infrastructure-expenditures-balance="totalData.totalInfrastructureExpendituresBalance"
      @input="onInputInfrastructureField"
    />

    <content-section
      v-if="showMonthlyReport && selectedMonthlyReport.length > 0 && authStore.canAny(['ida_forms.add_pom_1', 'ida_forms.change_pom_1'])"
      class="bg-stone-100 fixed z-50 bottom-0 right-0 w-full lg:w-[calc(100%-260px)] border-t border-t-zinc-400 !py-0"
    >
      <div class="flex">
        <basic-button
          v-if="isFF12Verified === false"
          class="my-5 lg:min-w-[220px]"
          @click="save"
        >
          <i class="las la-check text-2xl mr-2 lg:mr-6"></i>
          {{ gettext('Save') }}
        </basic-button>
        <basic-button
          v-if="isFF12Verified === false"
          class="ml-5 my-5 lg:min-w-[220px]"
          theme="primaryOutline"
          @click="updateMonthlyReportStatus(SUKU_BUDGET_MONTHLY_REPORT_STATUS.verified)"
        >
          {{ gettext('Verify') }}
        </basic-button>
        <basic-button
          v-if="isFF12Verified === true"
          class="ml-5 my-5 lg:min-w-[220px]"
          theme="primaryOutline"
          @click="updateMonthlyReportStatus(SUKU_BUDGET_MONTHLY_REPORT_STATUS.unverified)"
        >
          {{ gettext('Unverifying') }}
        </basic-button>
        <basic-button
          v-if="!isFiscalYearClosed"
          theme="red"
          class="ml-5 my-5 lg:min-w-[220px]"
          @click="closeFiscalYear"
        >
          <i class="las la-times text-2xl mr-2"></i>
          {{ gettext('Close fiscal year') }}
        </basic-button>
      </div>
    </content-section>
  </FormKit>
  <!-- end: edit and create section -->
  <MonthlyReportOperationalModal
    v-if="activeBudget === SUKU_BUDGET_TYPE.operational"
    ref="monthlyReportModal"
    :operational-subsidy1000="monthlyReportData?.data?.operational_subsidy_1000 ?? 0"
    :last-year-balance1001="monthlyReportData?.data?.last_year_balance_1001 ?? 0"
    :interest1002="monthlyReportData?.data?.interest_1002 ?? 0"
    :other1003="monthlyReportData?.data?.other_1003 ?? 0"
    :total-operational-receipts="monthlyReportData?.total_operational_receipts ?? 0"
    :community-meetings2000="monthlyReportData?.data?.community_meetings_2000 ?? 0"
    :community-training2001="monthlyReportData?.data?.community_training_2001 ?? 0"
    :incentive-for-suku2002="monthlyReportData?.data?.incentive_for_suku_2002 ?? 0"
    :project-admin-cost2003="monthlyReportData?.data?.project_admin_cost_2003 ?? 0"
    :total-operational-expenditures="monthlyReportData?.total_operational_expenditures"
    :balance-cash="monthlyReportData?.balance_cash ?? 0"
    :balance-bank="monthlyReportData?.balance_bank ?? 0"
    :total-balance="monthlyReportData?.total_balance"
    :reporting-period="reportingPeriod"
  />
  <MonthlyReportInfrastructureModal
    v-else
    ref="monthlyReportModal"
    :reporting-period="reportingPeriod"
    :total-infrastructure-receipts="monthlyReportData?.total_infrastructure_receipts ?? 0"
    :infrastructure-fund="monthlyReportData?.data?.repeaterInfrastructureFund ?? []"
    :total-infrastructure-expenditures="monthlyReportData?.total_infrastructure_expenditures"
    :infrastructure-balance="infrastructureMontlyReportBalance.balance"
    :total-balance="monthlyReportData?.total_balance"
  />
</template>
