<script setup lang="ts">
import { FormKitSchema } from '@formkit/vue'
import { FormKitSchemaFormKit, FormKitSchemaNode } from '@formkit/core'
import { formatCurrencyWithDollar } from '../../_helpers/common.helpers'
import { OperationalMonthlyReportBalance } from '../../stores/sukubudget'
import { MonthlyReportFormKitDataSchema } from '../../_types/sukubudget'
defineProps<{
    formkitSchemaData: MonthlyReportFormKitDataSchema
    monthlyReportBalance: OperationalMonthlyReportBalance
    totalOperationalFundBalanceThisYear: number
    totalDifferenceBalance: number
}>()

const operationalReportSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
  {
    $el: 'tbody',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1000 - Subsidy Operational PNDS")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'operational_subsidy_1000',
                id: 'operational_subsidy_1000',
                key: 'operational_subsidy_1000',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.operational_subsidy_1000)+$parseToFloat($get(operational_subsidy_1000).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.operational_subsidy_1000)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1001 - Last Year Balance")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'last_year_balance_1001',
                id: 'last_year_balance_1001',
                key: 'last_year_balance_1001',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.last_year_balance_1001)+$parseToFloat($get(last_year_balance_1001).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.last_year_balance)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },

      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1002 - Interest")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'interest_1002',
                id: 'interest_1002',
                key: 'interest_1002',
                outerClass: '!mb-0',
                value: '0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.interest_1002)+$parseToFloat($get(interest_1002).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'p',
                attrs: {
                  class: 'font-semibold text-sm'
                },
                children: ['$gettext("Not applicable")']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      },

      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("1003 - Other")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'other_1003',
                id: 'other_1003',
                key: 'other_1003',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.other_1003)+$parseToFloat($get(other_1003).value))']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'p',
                attrs: {
                  class: 'font-semibold text-sm'
                },
                children: ['$gettext("Not applicable")']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      }
    ]
  },
  {
    $el: 'tfoot',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            attrs: {
              class: 'font-semibold'
            },
            children: ['$gettext("Total operational receipts (USD)")']
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($get(other_1003).value)+$parseToFloat($get(interest_1002).value)+$parseToFloat($get(last_year_balance_1001).value)+$parseToFloat($get(operational_subsidy_1000).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($totalData.totalOperationalReceiptsCummulative)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.total_operational_receipts)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              colspan: '2'
            }
          }
        ]
      }
    ]
  }
]

const operationalExpendituresSchema: Array<FormKitSchemaFormKit | FormKitSchemaNode> = [
  {
    $el: 'tbody',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("2000 - Community Meeting")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'community_meetings_2000',
                id: 'community_meetings_2000',
                key: 'community_meetings_2000',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.community_meetings_2000)']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.community_meetings_2000-($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value)))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: [
                  {
                    $el: 'span',
                    children: {
                      if: '$parseToFloat($monthlyReportBudget.community_meetings_2000) !== 0',
                      then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.community_meetings_2000)+$parseToFloat($get(community_meetings_2000).value))/$monthlyReportBudget.community_meetings_2000*100)',
                      else: '0'
                    }
                  },
                  '%'
                ]
              }
            ]
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("2001 - Community Training")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'community_training_2001',
                id: 'community_training_2001',
                key: 'community_training_2001',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.community_training_2001)']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.community_training_2001-($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value)))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: [
                  {
                    $el: 'span',
                    children: {
                      if: '$parseToFloat($monthlyReportBudget.community_training_2001) !== 0',
                      then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.community_training_2001)+$parseToFloat($get(community_training_2001).value))/$monthlyReportBudget.community_training_2001*100)',
                      else: '0'
                    }
                  },
                  '%'
                ]
              }
            ]
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("2002 - Incentive for Suku team")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'incentive_for_suku_2002',
                id: 'incentive_for_suku_2002',
                key: 'incentive_for_suku_2002',
                outerClass: '!mb-0',
                value: '0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.incentive_for_suku_2002)']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.incentive_for_suku_2002-($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value)))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: [
                  {
                    $el: 'span',
                    children: {
                      if: '$parseToFloat($monthlyReportBudget.incentive_for_suku_2002) !== 0',
                      then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.incentive_for_suku_2002)+$parseToFloat($get(incentive_for_suku_2002).value))/$monthlyReportBudget.incentive_for_suku_2002*100)',
                      else: '0'
                    }
                  },
                  '%'
                ]
              }
            ]
          }
        ]
      },
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            children: ['$gettext("2003 - Project Administration Cost")']
          },
          {
            $el: 'td',
            children: [
              {
                $formkit: 'currency',
                name: 'project_admin_cost_2003',
                id: 'project_admin_cost_2003',
                key: 'project_admin_cost_2003',
                outerClass: '!mb-0'
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.project_admin_cost_2003)']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.project_admin_cost_2003-($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value)))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: [
                  {
                    $el: 'span',
                    children: {
                      if: '$parseToFloat($monthlyReportBudget.project_admin_cost_2003) !== 0',
                      then: '$parseToFloat($parseToFloat($parseToFloat($monthlyReportCommulative.project_admin_cost_2003)+$parseToFloat($get(project_admin_cost_2003).value))/$monthlyReportBudget.project_admin_cost_2003*100)',
                      else: '0'
                    }
                  },
                  '%'
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    $el: 'tfoot',
    children: [
      {
        $el: 'tr',
        children: [
          {
            $el: 'td',
            attrs: {
              class: 'font-semibold'
            },
            children: ['$gettext("Total operational expenditures (USD)")']
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($parseToFloat($get(community_meetings_2000).value)+$parseToFloat($get(community_training_2001).value)+$parseToFloat($get(incentive_for_suku_2002).value)+$parseToFloat($get(project_admin_cost_2003).value))']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($totalData.totalOperationalExpendituresCummulative)']
              }
            ]
          },
          {
            $el: 'td',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($monthlyReportBudget.total_operational_expenditures)']
              }
            ]
          },
          {
            $el: 'td',
            attrs: {
              class: '!border-r-0'
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border w-full bg-white border-zinc-400 rounded-lg px-4 h-[40px] flex items-center bg-gray-200/50'
                },
                children: ['$parseToCurrency($totalData.totalOperationalExpendituresBalance)']
              }
            ]
          },
          {
            $el: 'td'
          }
        ]
      }
    ]
  }
]

</script>
<template>
  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-file-invoice-dollar text-gray-400 mr-2"></i>
    {{ gettext('Operational receipts') }}
  </h2>

  <table class="table table-primary table-primary--no-gap table-primary-narrow w-full text-left mt-6">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Item') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('This month') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Cummulative') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
          {{ gettext('Budget') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
        </th>
      </tr>
    </thead>
    <FormKitSchema
      :schema="operationalReportSchema"
      :data="formkitSchemaData"
    />
  </table>

  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-money-check-alt text-gray-400 mr-2"></i>
    {{ gettext('Operational expenditures') }}
  </h2>

  <table class="table table-primary table-primary--no-gap table-primary-narrow w-full text-left mt-6">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Item') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('This month') }}
        </th>
        <th class="w-[120px] min-w-[120px]">
          {{ gettext('Cummulative') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
          {{ gettext('Budget') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
          {{ gettext('Balance') }}
        </th>
        <th class="w-[120px] min-w-[120px] !border-r-0">
          {{ gettext('% Spent') }}
        </th>
      </tr>
    </thead>
    <FormKitSchema
      :schema="operationalExpendituresSchema"
      :data="formkitSchemaData"
    />
  </table>

  <h2 class="text-1.5xl font-bold mt-9">
    <i class="las la-balance-scale text-gray-400 mr-2"></i>
    {{ gettext('Operational fund balance this year') }}
  </h2>

  <table class="table table-secondary w-full text-left mt-6 max-w-[60%] text-2sm">
    <thead>
      <tr>
        <th class="w-[320px] min-w-[320px]">
          {{ gettext('Operational fund balance for this year') }}
        </th>
        <th class="w-[120px] min-w-[120px]"></th>
        <th class="w-[120px] min-w-[120px]">
          <strong class="text-xl">{{ formatCurrencyWithDollar(totalOperationalFundBalanceThisYear) }}
          </strong>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          colspan="3"
          class="font-black"
        >
          {{ gettext('All operational fund balance') }}
        </td>
      </tr>
      <tr>
        <td>{{ gettext('Cash in hand') }}</td>
        <td>
          {{ formatCurrencyWithDollar(monthlyReportBalance.cash) }}
        </td>
        <td>
          {{ formatCurrencyWithDollar(monthlyReportBalance.commulative_cash) }}
        </td>
      </tr>
      <tr>
        <td class="!border-b !border-b-zinc-450">
          {{ gettext('Bank in balance') }}
        </td>
        <td class="border-b !border-b-zinc-450">
          {{ formatCurrencyWithDollar(monthlyReportBalance.bank) }}
        </td>
        <td class="border-b !border-b-zinc-450">
          {{ formatCurrencyWithDollar(monthlyReportBalance.commulative_bank) }}
        </td>
      </tr>
      <tr>
        <td
          colspan="2"
          class="font-black !bg-zinc-400 !border-r-0 !border-l-zinc-450 !border-b !border-b-zinc-450"
        >
          {{ gettext('Total operational balance (cash & bank)') }}
        </td>
        <td class="font-semibold !bg-zinc-400 !border-r-zinc-450 !border-b !border-b-zinc-450">
          {{ formatCurrencyWithDollar(monthlyReportBalance.total_operational_balance) }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td
          class="font-semibold !bg-red-400/30 !border-r-red-300"
          colspan="2"
        >
          {{ gettext('Difference') }}
        </td>
        <td class="text-base font-semibold !bg-red-400/30 !border-l-red-300">
          <p class="text-xs text-pink-700">
            {{ gettext('ALLOCATION OVER') }}
          </p>
          <h4 class="text-xl">
            {{ formatCurrencyWithDollar(totalDifferenceBalance) }}
          </h4>
        </td>
      </tr>
    </tfoot>
  </table>
</template>
